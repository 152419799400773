.QModalDividerContainer {
  display: flex;
  flex-direction: row;
  padding: 16px 0px;
}

.QModalDividerLeft {
  border-top: 1px solid #e5e7eb;
  padding-right: 13px;
  width: 35%;
  margin-left: 0;
}

.QModalDividerRight {
  border-top: 1px solid #e5e7eb;
  padding-left: 13px;
  width: 35%;
  margin-right: 0;
}
