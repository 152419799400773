.QModalCloseButton {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .QModalCloseButton {
    margin-right: -4px;
  }
}