.QModalBodyRight {
  display: flex;
  flex-direction: column;
  justify-items: center;
  padding: 0 24px;
  width: 100%;
}

.QModalBodyRight>.QModalTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #212429;
}

@media screen and (min-width: 768px) {
  .QModalBodyRight {
    width: 44%;
    padding: 0 24px 0 0;
  }

  .QModalBodyRight>form>.QModalTitle {
    width: 325px
  }
}