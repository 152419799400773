.QBodyContainer {
  display: flex;
  flex-direction: row;
}

.QMensagemLogin {
  display: flex;
  flex-direction: row;
  font-size: 12px !important;
  margin-top: 8px;
  margin-bottom: 27px;
}

.QMensagemLogin>.QModalLink {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #277BD9;
}

.QForgetContainer {
  margin-top: 12px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.QForgetContainer>.QModalLink {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #277BD9;

}

.QSocialLoginContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.QSocialLoginContainer>.QModalButton {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  align-self: center;
}

.QGoogleLogo {
  margin-right: 15px;
}

.QFacebookLogo {
  margin-right: 15px;
}

@media screen and (min-width: 768px) {
  .QBodyContainer {
    padding: 21px 15px;
  }

  .QSocialLoginContainer>.QModalButton {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    width: 345px;
    align-self: center;
  }
}
