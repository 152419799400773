.QModalBodyLeft {
  display: flex;
  flex-direction: column;
  padding-right: 24px;
  width: 56%;
}


@media screen and (max-width: 768px) {
  .QModalBodyLeft {
    display: none;
  }
}