.QModalOfferInformation {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 314px;
}

.QOfferTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  color: #FFFFFF;
  margin-top: 115px;
}

.QOfferOldValue {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #FFFFFF;
  margin-top: 16px;
}

.QOfferOldValue .Decorated {
  text-decoration: line-through;
}

.QOfferNewValue {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.03em;
  color: #FFFFFF;
}