.QModalLink {
  color: var(--ModalDarkBlue);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  cursor: pointer;
}