.QModalButton {
  position: relative;
  overflow: hidden;
  color: #ffffff;
  background-color: #07a3b2;
  outline: 0;
  border: 0;
  border-radius: 36px;
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  box-sizing: border-box;
}

.QModalButton:disabled {
  background-color: var(--ButtonPrimaryDisabled);
}

span.ripple {
  pointer-events: none;
  position: absolute;
  border-radius: 100%;
  transform: scale(0);
  animation-name: ripple;
  background-color: rgba(255, 255, 255, 0.2);
  animation-duration: 550ms;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

@keyframes ripple {
  to {
    transform: scale(2);
    opacity: 0;
  }
}

@media screen and (min-width: 768px) {
  .QModalButton {
    width: 352px;
    height: 40px;
    align-self: center;
  }
}
