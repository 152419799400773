.loader-container {
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100vh;
  width: 100vw;
  font-family: Helvetica;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  display: inline-block;
  width: 80px;
  height: 80px;
}

.loader:after {
  content: " ";
  display: block;
  width: 42px;
  height: 42px;
  margin: 8px;
  border-radius: 50%;
  border: 4px solid #222;
  border-color: #222 transparent #222 transparent;
  animation: loader-animation 1.2s linear infinite;
}

@keyframes loader-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
