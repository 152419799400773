.QModalInputContainer {
  position: relative;
}

.QStatusIcon {
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -14px;
}

.QModalInput {
  border: none;
  background-color: #f4f5f7;
  border-radius: 4px;
  /* max-width: 312px; */
  width: 100%;
  height: 40px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: #4b5563;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  margin-top: 8px;
  padding: 0 16px;
  box-sizing: border-box;
}

.QModalInput:focus {
  outline: none;
}

input[type="password"].QModalInput,
input[type="text"].QModalInput {
  padding: 8px 16px;
  box-sizing: border-box;
}

input[type="text"].QModalInput.success,
input[type="password"].QModalInput.success {
  padding: 8px 35px 8px 16px;
  border: 1px solid #1c986c;
}

input[type="text"].QModalInput.error,
input[type="password"].QModalInput.error {
  padding: 8px 35px 8px 16px;
  border: 1px solid #f1173a;
  margin-bottom: unset;
}

input[type="text"].QModalInput.error+.QStatusIcon,
input[type="password"].QModalInput.error+.QStatusIcon {
  margin-top: -10px;
}

.QInputErrorMessage {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #910e23;
  margin-top: 2px;
}

.InputProtectedContainer {
  display: flex;
  flex-direction: row;
  position: absolute;
  right: 10px;
  top: 41%;
  align-items: center;
}

.InputCompraSegura {
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  color: #23be87;
  margin-left: 10px;
  display: none;
}

.QInputStatusIcon {
  position: absolute;
  top: 18px;
  right: 0;
  margin-right: 18px;
}

@media screen and (min-width: 768px) {
  .QModalInput {
    /* width: 352px; */
    height: 40px;
  }

  .InputCompraSegura {
    display: inline-block;
  }
}