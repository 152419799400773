.QModalCheckboxContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.QModalCheckboxDisplay {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  cursor: pointer;
}

.QModalCheckboxIcon {
  display: none;
  position: absolute;
  padding: 5px;
  pointer-events: none;
  right: 8px;
  bottom: 9px;
}

[type="checkbox"] {
  -webkit-appearance: none;
  cursor: pointer;
  -moz-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: var(--White);
  border: 1px solid var(--CheckboxLightGray);
  box-sizing: border-box;
  border-radius: 3px;
  position: relative;
}

[type="checkbox"]:checked::before {
  content: "";
  width: 20px;
  height: 20px;
  background: var(--CheckboxLightBlue);
  border: 1px solid var(--CheckboxDarkBlue);
  position: absolute;
  box-sizing: border-box;
  border-radius: 3px;
  bottom: -1px;
  left: -1px;
}

[type="checkbox"]:checked + .QModalCheckboxIcon {
  display: unset;
  padding: 0;
}
