.QModalContainerDefault {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  outline: none;
}

.QModalContainerCadastro {
  width: 100%;
  height: 100%;
  max-width: 425px;
  position: absolute;
  bottom: 0px;
  background-color: #ffffff;
  display: flex;
  border-radius: 16px 16px 0px 0px;
  flex-direction: column;
}

.QModalContainerLogin {
  width: 100%;
  max-width: 425px;
  height: 100%;
  position: absolute;
  bottom: 0px;
  background-color: #ffffff;
  display: flex;
  border-radius: 16px 16px 0px 0px;
  flex-direction: column;
}

.QModalContainerForget {
  width: 100%;
  max-width: 425px;
  max-height: 418px;
  height: 100%;
  position: absolute;
  bottom: 0px;
  background-color: #ffffff;
  display: flex;
  border-radius: 16px 16px 0px 0px;
  flex-direction: column;
}

.QModalContainerLoading {
  width: 100vw;
  height: 100vh;
}

.QModalContainerCadastro:focus,
.QModalContainerLogin:focus,
.QModalContainerForget:focus,
.QModalContainerLoading:focus {
  outline: none;
}

.QModalContainerIframe {
  width: 90%;
}

@media screen and (min-width: 768px) {
  .QModalContainerLogin {
    width: 360px;
    height: 574px;
  }

  .QModalContainerCadastro {
    width: 360px;
    height: 630px;
    max-width: unset;
  }

  .QModalContainerCadastro,
  .QModalContainerLogin,
  .QModalContainerForget {
    width: 870px;
    height: 598px;
    max-height: unset;
    max-width: unset;
    border-radius: 16px 16px 16px 16px;
    position: relative;
    bottom: unset;
  }
}
