:root {
  --PrimaryColor: #16BDCA;
  --CheckboxLightBlue: #EDF8FF;
  --CheckboxDarkBlue: #BCE3EC;
  --CheckboxLightGray: #EAEBED;
  --ModalDarkBlue: #277BD9;
  --White: #ffffff;
  --FontGrayColor: #495058;
  --ButtonPrimaryDisabled: #8ad8df;
  --MainBackgroundColor: #F3F6F6;
  --FooterFontGray: #C1C7D0;
  --FooterFontLightGray: #FAF9F8;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Open Sans', 'Times New Roman', Times, serif;
  /* TODO*/
  background: #F8FAFC;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.QNegrito {
  font-weight: bold;
}

.QNegrito.i {
  font-style: italic;
}
