.QModalBackgroundImg {
  width: 447px;
  height: 562px;
  position: relative;
  display: inline-block;
  border-radius: 15px;
}

.QModalInner {
  position: absolute;
  display: flex;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  z-index: 1;
  justify-content: center;
}

.QModalBackgroundImg:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  background: linear-gradient(
    180deg,
    rgba(33, 36, 41, 0) 43.67%,
    rgba(33, 36, 41, 0.6) 72.21%
  );
  border-radius: 15px;
}

.QModalBackgroundImg.Offer:after {
  background: linear-gradient(
    180deg,
    rgba(22, 30, 46, 0.65) 1.56%,
    rgba(0, 0, 0, 0.65) 100%
  );
}

.QModalBackgroundInnerImg {
  display: block;
  width: 447px;
  height: 562px;
  border-radius: 15px;
}

.QLogoWhite {
  position: absolute;
  bottom: 16px;
  transform: translateX(-50%);
  left: 50%;
  z-index: 1;
  color: white;
}

.QLogoParceiro{
  position: absolute;
  bottom: 16px;
  transform: translateX(-50%);
  left: 50%;
  z-index: 1;
  color: white;
  max-width: 40%;
}
