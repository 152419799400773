.QBodyContainer {
  display: flex;
  flex-direction: row;
}

.QEmailReSentIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ecf9f5;
  border-radius: 16px;
  width: 80px;
  height: 80px;
}

.QEmailSentIcon,
.QEmailReSentIcon {
  align-self: center;
  margin: 20px 0px 8px 0px;
}

@media screen and (min-width: 768px) {
  .QBodyContainer {
    padding: 21px 15px;
  }

  .QEmailSentIcon,
  .QEmailReSentIcon {
    margin: 168px 0px 16px 0px;
  }
}

.PinFieldRecover {
  border: none;
  background: #f4f5f7;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  color: #4b5563;
  border-radius: 4px;
  width: 100%;
  height: 50px;
  text-align: center;
}
