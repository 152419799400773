.QModalHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 16px 16px 24px;
}

.QModalLogo {
  height: 20px;
  color: #1432FF;
}

@media screen and (min-width: 768px) {
  .QModalLogo {
    display: none;
  }

  .QModalHeader {
    display: unset;
    position: absolute;
    top: 21px;
    right: 15px;
    padding: unset;
  }
}
