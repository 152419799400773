.QBodyContainer {
  display: flex;
  flex-direction: row;
}

.QMensagemLogin {
  display: flex;
  flex-direction: row;
  font-size: 12px !important;
  margin-top: 8px;
  margin-bottom: 24px;
}

.QCheckboxContainer {
  margin-top: 8px;
  margin-bottom: 17px;
}

.QSocialLoginContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.QSocialLoginContainer>.QModalButton {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  align-self: center;
}

.QGoogleLogo {
  margin-right: 16px;
}

.QGoogleBtnWeb {
  display: none;
}

.QGoogleBtnMobile {
  display: flex;
  justify-content: center;
}

.QFacebookLogo {
  margin-right: 16px;
}

.SignupLinks {
  font-size: 10px;
}

.QCadastroSmallDescription{
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #6D767E;
  margin: unset;
}

@media screen and (min-width: 768px) {
  .QBodyContainer {
    padding: 21px 15px;
  }

  .SignupLinks {
    font-size: 12px;
  }

  .QCadastroSmallDescription{
    font-size: 14px;
  }
  
  .QGoogleBtnWeb {
    display: flex;
    justify-content: center;
  }

  .QGoogleBtnMobile {
    display: none;
  }

  .QSocialLoginContainer>.QModalButton {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    width: 345px;
    align-self: center;
  }
}
